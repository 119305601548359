const format = {

  compressedMoney: (value: number | undefined): string => {
    if (!value) return 'R$ ' + 0

    const valueFormatted = new Intl.NumberFormat('pt-BR', { notation: 'compact', style: 'currency', currency: 'BRL' }).format(value)
    return valueFormatted.toUpperCase()
  },

  onlyMoney: (value: string): number | undefined => {
    if (!value) return undefined
    if (typeof value === 'number') return value
    if (value.slice(-3).includes('.')) return Number(value)

    return Number(value.replace(/[^0-9,]/g, '').replace(/,/g, '.'))
  },

  showMoney: (value: number | undefined | string): string => {
    if (typeof value === 'string') value = Number(value.replace(',', '.'))
    if (!value) return 'R$ ' + 0
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  },

  precision: (value: number | string | undefined, precision: number | undefined = 2): number => {
    if (typeof value === 'number') return Number(value.toFixed(precision))
    if (typeof value === 'string' && !isNaN(Number(value))) return Number(Number(value).toFixed(precision))
    return 0
  }
}

export default format
