import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { ArrowCollapsed } from 'assets/svgs/customIcons'

interface IToggleCardExtend {
  background: string | number
  title: string | React.ReactElement
  children: React.ReactNode
}

function ToggleCardExtend ({ title, children, background }: IToggleCardExtend) {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <div className="toggle-card-extend">
      <div onClick={() => setIsCollapsed(prev => !prev)} style={{ backgroundColor: `${background}` }} className={`toggle-card-extend__card ${!isCollapsed && ' toggle-card-extend__card--collapsed'}`}>
        <Row align="middle" className="toggle-card-extend__header">
          <Col flex="0 1">

            <ArrowCollapsed className={isCollapsed ? 'toggle-card-extend__arrow-down' : 'toggle-card-extend__arrow-up'} />
          </Col>
          <Col flex="1 1" className="center-text">
            <div className={`toggle-card-extend__card-title ${!isCollapsed && ' toggle-card-extend__card-title--collapsed'}`}>{title}</div>
          </Col>
        </Row>

      </div>
      <Row className={`toggle-card-extend__body ${!isCollapsed && 'toggle-card-extend__body--hidden'}`} style={{ backgroundColor: `${background}` }}>
        <div className="toggle-card-extend__body-children">
          {children}
        </div>
      </Row>
    </div>
  )
}

export default ToggleCardExtend
