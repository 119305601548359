import React from 'react'
import { Pie } from '@ant-design/plots'

interface IDonutGraph {
  data: { type: string, value: string, graph: number, total: string, percentage: string, lucro: number }[]
}

interface IValue {
  type: string,
  value: string
}

const DonutGraph = ({ data }: IDonutGraph) => {
  const config = {
    appendPadding: 10,
    data,
    angleField: 'graph',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.87,
    color: ({ type }: any) => {
      if (type.toUpperCase() === 'DIGITAL') {
        return '#A17CCE'
      }
      if (type.toUpperCase() === 'SMS/WHATSAPP') {
        return '#312E52'
      }
      if (type.toUpperCase() === 'URA') {
        return '#3AB185'
      }
      if (type.toUpperCase() === 'AFILIADOS') {
        return '#FD7D7F'
      }
      return 'yellow'
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    legend: false,
    label: false,
    tooltip: {
      formatter: (value: IValue) => {
        const currentData = data.filter((item) => item.type === value.type)[0]
        return { name: currentData.type, value: currentData.value || 0 }
      }
    },
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontSize: '3rem',
          whiteSpace: 'pre-wrap',
          fontWeight: '500',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        customHtml: () => {
          return `<div style="color:#52BE6B;" > ${data[0].total || 0} </div>`
        }
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '1.9rem',
          fontWeight: '300',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        customHtml: () => {
          return `<div style="color: #203264;"> ${data[0].percentage || 0} </div>`
        }
      }
    }
  }

  // @ts-ignore
  return <Pie {...config} />
}

export default DonutGraph
