import { compose, createStore } from 'redux'
import { persistStore } from 'redux-persist'

import rootReducer from 'store/modules/rootReducer'

const composeEnhancers = (
  typeof window !== 'undefined' &&
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as any] as any) &&
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as any] as any)({ trace: true, traceLimit: 25 })
) || compose

const store = createStore(rootReducer, composeEnhancers())
const persistor = persistStore(store)

export { store, persistor }
