import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import GraphResults from 'views/GraphResults/GraphResults'
import Home from 'views/Home/Home'

function Router () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/results" element={<GraphResults />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
