import React from 'react'
import { Form, Tooltip } from 'antd'
import InputMoney from 'components/InputMoney/InputMoney'
import InputPercentage from 'components/InputPercentage/InputPercentage'
import { productsName, productTooltip } from './constants'
import tooltipHelper from 'assets/svgs/tooltip-helper.svg'
import { CarouselRef } from 'antd/lib/carousel'

type Props = {
  product: string
  onBlur: React.FocusEventHandler<HTMLInputElement>
  refs: React.RefObject<CarouselRef>
}

function ProductCard ({ product, onBlur, refs }: Props) {
  return (
    <div className='product-card' tabIndex={0}>
      <div className='product-card__header'>
        <h1>{(productsName as { [key: string]: string })[product] || ''}</h1>
      </div>
      <div className='product-card__body'>
        <Form.Item
          name={['produtos', product, 'ticketMedio']}
          label={
            <div className="product-card__tooltip-container">
              <Tooltip placement="rightTop" title={(productTooltip as { [key: string]: any })[product] || ''} >
                <img className="product-card__tooltip-icon" src={tooltipHelper} alt="" />
              </Tooltip>
              <span>Ticket Médio de Venda do Produto</span>
            </div>
          }
          data-cy='ticketMedio'
        >
          <InputMoney
            onBlur={onBlur} />
        </Form.Item>

        <Form.Item
          name={['produtos', product, 'percentualMedio']}
          label='Percentual Médio Pago'
          data-cy='percentualMedio'
        >
          <InputPercentage
            onKeyDown={(key) => {
              if (key.code === 'Tab') {
                if (key.shiftKey) {
                  refs.current?.prev()
                  return
                }
                refs.current?.next()
              }
            }}
            onBlur={onBlur} />
        </Form.Item>

        <Form.Item
          name={['produtos', product, 'digital']}
          label='Google, Facebook, Instagram'
          data-cy='digital'
        >
          <InputMoney tabIndex={-1} disabled />
        </Form.Item>

        <Form.Item
          name={['produtos', product, 'smsWhatsapp']}
          label='SMS e WhatsApp'
          data-cy='smsWhatsapp'
        >
          <InputMoney tabIndex={-1} disabled />
        </Form.Item>

        <Form.Item
          name={['produtos', product, 'campanhaUra']}
          label='Campanha de URA'
          data-cy='campanhaUra'
        >
          <InputMoney tabIndex={-1} disabled />
        </Form.Item>

        <Form.Item
          name={['produtos', product, 'afiliados']}
          label='Afiliados'
          data-cy='afiliados'
        >
          <InputMoney tabIndex={-1} disabled />
        </Form.Item>

        <Form.Item
          name={['produtos', product, 'total']}
          label='Valor Total'
          data-cy='total'
        >
          <InputMoney tabIndex={-1} disabled />
        </Form.Item>
      </div>
    </div>
  )
}

export default ProductCard
