export type _channels = 'digital' | 'sms' | 'ura' | 'afiliados'

export const allChannel = [
  { label: 'Google, Facebook, Instagram', value: 'digital' },
  { label: 'SMS e WhatsApp', value: 'sms' },
  { label: 'Campanha de URA', value: 'ura' },
  { label: 'Afiliados', value: 'afiliados' }
]

export const allProducts = [
  { label: 'Margem', value: 'margem' },
  { label: 'FGTS', value: 'fgts' },
  { label: 'Cartão', value: 'cartao' },
  { label: 'Saque', value: 'saque' },
  { label: 'Refin', value: 'refin' },
  { label: 'Port', value: 'port' },
  { label: 'Conta Digital', value: 'contaDigital' },
  { label: 'Maquininha', value: 'maquininha' }
]
