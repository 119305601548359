/* eslint-disable react/prop-types */
import React from 'react'
import CurrencyInput from 'react-currency-input-field'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  loading?: boolean
  rest?: any
  onValueChange?: any
  suffix?: string
  topValue?: number
 }

function InputPercentage ({ className, onChange, topValue = 100, ...rest }: InputProps) {
  return (
    <CurrencyInput
      {...(rest as any)}
      suffix={'%'}
      onValueChange={onChange}
      className={className ? className : 'ant-input'}
      decimalsLimit={2}
      decimalSeparator=","
      groupSeparator="."
      onInput={(e) => {
        const value = (e.target as HTMLInputElement).value
        const numericalValue = Number(value.match(/[\d^,]/gi)?.join(''))
        const fractionalValue = value.match(/,\d+$/gi)?.join('') || ''
        if (numericalValue > topValue || fractionalValue.length > 3) {
          (e.target as HTMLInputElement).value = String(numericalValue).slice(0, -1)
        }
      }}
    />
  )
}

export default InputPercentage
