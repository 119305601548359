import React from 'react'
import Routes from './routes'
import './styles/main.less'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'store'
import AppLayout from 'layouts/AppLayout'

function App () {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppLayout>
          <Routes />
        </AppLayout>
      </PersistGate>
    </Provider>
  )
}

export default App
