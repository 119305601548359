import { useSelector } from 'react-redux'
import { IRootReducer } from 'store/modules/rootReducer'

const useCalculator = () => {
  const calculator = useSelector((state: IRootReducer) => state.calculator)
  return calculator
}

export {
  useCalculator
}
