import { ICalculator } from 'store/modules/calculator/interfaces'
import format from 'utils/format'

export const formatCalculation = (calc: ICalculator): ICalculator => {
  return ({
    custoSms: {
      valorInvestimento: format.onlyMoney(String(calc?.custoSms.valorInvestimento)) || 0,
      custoOperacao: format.onlyMoney(String(calc?.custoSms.custoOperacao)) || 0,
      comissao: calc?.custoSms.comissao ? Number(String(calc?.custoSms.comissao).replace(',', '.')) : 0
    },
    custoDigital: {
      valorInvestimento: format.onlyMoney(String(calc?.custoDigital.valorInvestimento)) || 0,
      custoOperacao: format.onlyMoney(String(calc?.custoDigital.custoOperacao)) || 0,
      comissao: calc?.custoDigital.comissao ? Number(String(calc?.custoDigital.comissao).replace(',', '.')) : 0
    },
    custoUra: {
      valorInvestimento: format.onlyMoney(String(calc?.custoUra.valorInvestimento)) || 0,
      custoOperacao: format.onlyMoney(String(calc?.custoUra.custoOperacao)) || 0,
      comissao: calc?.custoUra.comissao ? Number(String(calc?.custoUra.comissao).replace(',', '.')) : 0
    },
    custoAfiliados: {
      valorInvestimento: format.onlyMoney(String(calc?.custoAfiliados.valorInvestimento)) || 0,
      custoOperacao: format.onlyMoney(String(calc?.custoAfiliados.custoOperacao)) || 0,
      comissao: calc?.custoAfiliados.comissao ? Number(String(calc?.custoAfiliados.comissao).replace(',', '.')) : 0
    },
    conversaoVenda: {
      diasUteis: calc?.conversaoVenda.diasUteis || 0,
      vendas: calc?.conversaoVenda.vendas || 0
    },
    ticketCanal: {
      digital: calc?.ticketCanal.digital || 0,
      smsWhatsapp: calc?.ticketCanal.smsWhatsapp || 0,
      campanhaUra: calc?.ticketCanal.campanhaUra || 0,
      afiliados: calc?.ticketCanal.afiliados || 0,
      totalTickets: calc?.ticketCanal.totalTickets || 0
    },
    produtos: {
      margem: {
        ticketMedio: calc?.produtos.margem.ticketMedio || 0,
        percentualMedio: calc?.produtos.margem.percentualMedio ? Number(String(calc?.produtos.margem.percentualMedio).replace(',', '.')) : 0,
        digital: calc?.produtos.margem.digital || 0,
        smsWhatsapp: calc?.produtos.margem.smsWhatsapp || 0,
        campanhaUra: calc?.produtos.margem.campanhaUra || 0,
        afiliados: calc?.produtos.margem.afiliados || 0,
        total: calc?.produtos.margem.total || 0
      },
      fgts: {
        ticketMedio: calc?.produtos.fgts.ticketMedio || 0,
        percentualMedio: calc?.produtos.fgts.percentualMedio ? Number(String(calc?.produtos.fgts.percentualMedio).replace(',', '.')) : 0,
        digital: calc?.produtos.fgts.digital || 0,
        smsWhatsapp: calc?.produtos.fgts.smsWhatsapp || 0,
        campanhaUra: calc?.produtos.fgts.campanhaUra || 0,
        afiliados: calc?.produtos.fgts.afiliados || 0,
        total: calc?.produtos.fgts.total || 0
      },
      cartao: {
        ticketMedio: calc?.produtos.cartao.ticketMedio || 0,
        percentualMedio: calc?.produtos.cartao.percentualMedio ? Number(String(calc?.produtos.cartao.percentualMedio).replace(',', '.')) : 0,
        digital: calc?.produtos.cartao.digital || 0,
        smsWhatsapp: calc?.produtos.cartao.smsWhatsapp || 0,
        campanhaUra: calc?.produtos.cartao.campanhaUra || 0,
        afiliados: calc?.produtos.cartao.afiliados || 0,
        total: calc?.produtos.cartao.total || 0
      },
      saque: {
        ticketMedio: calc?.produtos.saque.ticketMedio || 0,
        percentualMedio: calc?.produtos.saque.percentualMedio ? Number(String(calc?.produtos.saque.percentualMedio).replace(',', '.')) : 0,
        digital: calc?.produtos.saque.digital || 0,
        smsWhatsapp: calc?.produtos.saque.smsWhatsapp || 0,
        campanhaUra: calc?.produtos.saque.campanhaUra || 0,
        afiliados: calc?.produtos.saque.afiliados || 0,
        total: calc?.produtos.saque.total || 0
      },
      refin: {
        ticketMedio: calc?.produtos.refin.ticketMedio || 0,
        percentualMedio: calc?.produtos.refin.percentualMedio ? Number(String(calc?.produtos.refin.percentualMedio).replace(',', '.')) : 0,
        digital: calc?.produtos.refin.digital || 0,
        smsWhatsapp: calc?.produtos.refin.smsWhatsapp || 0,
        campanhaUra: calc?.produtos.refin.campanhaUra || 0,
        afiliados: calc?.produtos.refin.afiliados || 0,
        total: calc?.produtos.refin.total || 0
      },
      port: {
        ticketMedio: calc?.produtos.port.ticketMedio || 0,
        percentualMedio: calc?.produtos.port.percentualMedio ? Number(String(calc?.produtos.port.percentualMedio).replace(',', '.')) : 0,
        digital: calc?.produtos.port.digital || 0,
        smsWhatsapp: calc?.produtos.port.smsWhatsapp || 0,
        campanhaUra: calc?.produtos.port.campanhaUra || 0,
        afiliados: calc?.produtos.port.afiliados || 0,
        total: calc?.produtos.port.total || 0
      },
      contaDigital: {
        ticketMedio: calc?.produtos.contaDigital.ticketMedio || 0,
        percentualMedio: calc?.produtos.contaDigital.percentualMedio ? Number(String(calc?.produtos.contaDigital.percentualMedio).replace(',', '.')) : 0,
        digital: calc?.produtos.contaDigital.digital || 0,
        smsWhatsapp: calc?.produtos.contaDigital.smsWhatsapp || 0,
        campanhaUra: calc?.produtos.contaDigital.campanhaUra || 0,
        afiliados: calc?.produtos.contaDigital.afiliados || 0,
        total: calc?.produtos.contaDigital.total || 0
      },
      maquininha: {
        ticketMedio: calc?.produtos.maquininha.ticketMedio || 0,
        percentualMedio: calc?.produtos.maquininha.percentualMedio ? Number(String(calc?.produtos.maquininha.percentualMedio).replace(',', '.')) : 0,
        digital: calc?.produtos.maquininha.digital || 0,
        smsWhatsapp: calc?.produtos.maquininha.smsWhatsapp || 0,
        campanhaUra: calc?.produtos.maquininha.campanhaUra || 0,
        afiliados: calc?.produtos.maquininha.afiliados || 0,
        total: calc?.produtos.maquininha.total || 0
      }
    },
    totalProducao: {
      produtos: calc?.totalProducao.produtos || [],
      canais: calc?.totalProducao.canais || [],
      valorVendido: format.onlyMoney(String(calc?.totalProducao.valorVendido)) || 0,
      valorComissao: format.onlyMoney(String(calc?.totalProducao.valorComissao)) || 0,
      valorCustos: format.onlyMoney(String(calc?.totalProducao.valorCustos)) || 0
    },
    sistema: {
      valorImplementacao: format.onlyMoney(String(calc?.sistema.valorImplementacao)) || 0,
      valorManuntencao: format.onlyMoney(String(calc?.sistema.valorManuntencao)) || 0,
      custoSistema: format.onlyMoney(String(calc?.sistema.custoSistema)) || 0
    },
    resultados: {
      total: 0,
      porcentagem: 0,
      tabela: []
    }
  })
}
