import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import calculator from 'store/modules/calculator/reducer'
import { ICalculator } from './calculator/interfaces'

const calculatorPersistConfig = {
  key: 'calculator',
  storage
}

const rootReducer = combineReducers({
  calculator: persistReducer(calculatorPersistConfig, calculator)
})

export interface IRootReducer {
  calculator: ICalculator
}

export default rootReducer
