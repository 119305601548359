import React from 'react'
import DonutGraph from 'components/DonutGraph/DonutGraph'
import { useCalculator } from 'hooks'
import format from 'utils/format'
import { Col, Row, Tooltip } from 'antd'
import arrowCarrossel from 'assets/svgs/arrow-carrossel.svg'
import { useNavigate } from 'react-router'

function GraphResults () {
  const calculator = useCalculator()
  const navigate = useNavigate()

  const table = calculator.resultados?.tabela?.map((item) => {
    return {
      type: item.canal,
      graph: item.graph,
      value: format.showMoney(item.valor),
      lucro: item.lucro,
      total: format.compressedMoney(calculator?.resultados?.total || 0),
      percentage: ((calculator?.resultados?.porcentagem || 0)).toFixed(2) + '%'
    }
  })

  function colorPreview (channel: string) {
    switch (channel.toUpperCase()) {
      case 'DIGITAL':
        return '#A17CCE'
      case 'SMS/WHATSAPP':
        return '#312E52'
      case 'URA':
        return '#3AB185'
      case 'AFILIADOS':
        return '#FD7D7F'
      default:
        return 'yellow'
    }
  }

  return (
    <>
      <div className="graph-results__header">
        <img className="graph-results__header-icon" src={arrowCarrossel} alt="Ícone para voltar a página anterior" onClick={() => navigate('/')} />
      </div>
      <div className="graph-results" >
        <div>
          <h1>resultado</h1>
          <div className="graph-results__total">
            <p>Valor Total:</p>
            <p>{format.showMoney(calculator?.resultados?.total)}</p>
          </div>
        </div>

        <div className="graph-results__pie">
          <DonutGraph data={table} />
        </div>

        <div className="graph-results__table">
          <Row className="mb-1" justify="space-between">
            <Col flex="1 1 45%" className="graph-results__table-bold" >CANAL</Col>
            <Col flex="1 1 35%" className="graph-results__table-bold center-text flex flex-align-center">VALOR</Col>
            <Col flex="1 1 20%" className="graph-results__table-bold text-end flex flex-align-center">LUCRO %</Col>
          </Row>
          {calculator?.resultados?.tabela && Array.isArray(calculator.resultados.tabela) && calculator.resultados.tabela.map((item) => (
            <Row key={item.canal}>
              <Col flex="1 1 45%" className="flex flex-align-center graph-results__table-lighter graph-results__ellipsis">
                <div className="graph-results__preview-colors" style={{ backgroundColor: `${colorPreview(item.canal)}` }} />
                {item.canal}
              </Col>
              <Col flex="1 1 35%" className="center-text flex flex-align-center graph-results__table-lighter graph-results__ellipsis">
                <Tooltip title={format.showMoney(item.valor)}>
                  <span className="graph-results__ellipsis" >{format.showMoney(item.valor)}</span>
                </Tooltip>
              </Col>
              <Col flex="1 1 20%" className="graph-results__table-bold text-end flex flex-align-center graph-results__ellipsis">
                <Tooltip title={`${(item.lucro * 100).toFixed(2)}%`}>
                  <span className="graph-results__ellipsis" >{(item.lucro).toFixed(2)}%</span>
                </Tooltip>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </>
  )
}

export default GraphResults
