import React from 'react'
import Icon from '@ant-design/icons'

const ArrowCollapsedSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40.904" height="23.988" viewBox="0 0 40.904 23.988" fill="currentcolor">
    <g id="Grupo_6" data-name="Grupo 6" transform="translate(512.869 226.952) rotate(180)">
      <line id="Linha_5" data-name="Linha 5" x2="16.917" y2="16.917" transform="translate(475.5 206.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="5" />
      <line id="Linha_6" data-name="Linha 6" x1="16.917" y2="16.917" transform="translate(492.417 206.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="5" />
    </g>
  </svg>
)

// not working
const ArrowCarrosselSvg = () => (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 59" fill="currentColor"><defs></defs><g id="Camada_2" data-name="Camada 2"><g id="Camada_1-2" data-name="Camada 1"><path fill="#fff" d="M29.5,0A29.5,29.5,0,1,0,59,29.5,29.5,29.5,0,0,0,29.5,0ZM44.76,30.68h0L31.09,44.36a1.68,1.68,0,0,1-2.38,0h0L27.14,42.8a1.69,1.69,0,0,1,0-2.39l0,0,8.48-8.08H15.44a1.68,1.68,0,0,1-1.69-1.67h0V28.38a1.69,1.69,0,0,1,1.68-1.69H35.65l-8.48-8.07a1.68,1.68,0,0,1-.07-2.37l0-.05,1.56-1.56a1.69,1.69,0,0,1,2.38,0h0L44.76,28.3A1.69,1.69,0,0,1,44.76,30.68Z"/></g></g></svg>)

const PlusSignalSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.646" height="17" viewBox="0 0 17.646 17" fill="currentColor">
    <path id="Caminho_1" data-name="Caminho 1" d="M523.457,960.341a1.881,1.881,0,0,1-1.36-.525,1.746,1.746,0,0,1-.544-1.313,1.643,1.643,0,0,1,.544-1.28,1.95,1.95,0,0,1,1.36-.492H537.3a1.918,1.918,0,0,1,1.36.508,1.818,1.818,0,0,1,0,2.594,1.918,1.918,0,0,1-1.36.508Zm6.868,6.4a2.217,2.217,0,0,1-1.581-.591,1.976,1.976,0,0,1-.629-1.51v-12.8a1.953,1.953,0,0,1,.646-1.51,2.28,2.28,0,0,1,1.6-.591,2.144,2.144,0,0,1,1.581.591,2.039,2.039,0,0,1,.6,1.51v12.8a2.01,2.01,0,0,1-.611,1.51A2.207,2.207,0,0,1,530.325,966.741Z" transform="translate(-521.553 -949.741)" fill="#fff" />
  </svg>
)

const ArrowCollapsed = (props: any) => <Icon component={ArrowCollapsedSvg} {...props} />
const ArrowCarrossel = (props: any) => <Icon component={ArrowCarrosselSvg} {...props} />
const PlusSignal = (props: any) => <Icon component={PlusSignalSvg} {...props} />

export {
  ArrowCollapsed,
  ArrowCarrossel,
  PlusSignal
}
