import { ICalculator } from './interfaces'

export const finalCaculation:ICalculator = {
  custoSms: {
    valorInvestimento: 1000.00,
    custoOperacao: 10.0,
    comissao: 2
  },
  custoDigital: {
    valorInvestimento: 5000.00,
    custoOperacao: 10.0,
    comissao: 3
  },
  custoUra: {
    valorInvestimento: 999.00,
    custoOperacao: 10.0,
    comissao: 5
  },
  custoAfiliados: {
    valorInvestimento: 1000.00,
    custoOperacao: 10.0,
    comissao: 50
  },
  conversaoVenda: {
    diasUteis: 30,
    vendas: 24
  },
  ticketCanal: {
    digital: 10,
    smsWhatsapp: 10,
    campanhaUra: 10,
    afiliados: 10,
    totalTickets: 1000
  },
  produtos: {
    margem: {
      ticketMedio: 5000.00,
      percentualMedio: 11,
      digital: 1000.00,
      smsWhatsapp: 1000.00,
      campanhaUra: 1000.00,
      afiliados: 1000.00,
      total: 1000.00
    },
    fgts: {
      ticketMedio: 1400.00,
      percentualMedio: 8,
      digital: 1000.00,
      smsWhatsapp: 1000.00,
      campanhaUra: 1000.00,
      afiliados: 1000.00,
      total: 1000.00
    },
    cartao: {
      ticketMedio: 280.00,
      percentualMedio: 100,
      digital: 1000.00,
      smsWhatsapp: 1000.00,
      campanhaUra: 1000.00,
      afiliados: 1000.00,
      total: 1000.00
    },
    saque: {
      ticketMedio: 2000.00,
      percentualMedio: 6,
      digital: 1000.00,
      smsWhatsapp: 1000.00,
      campanhaUra: 1000.00,
      afiliados: 1000.00,
      total: 1000.00
    },
    refin: {
      ticketMedio: 3000.00,
      percentualMedio: 3.5,
      digital: 1000.00,
      smsWhatsapp: 1000.00,
      campanhaUra: 1000.00,
      afiliados: 1000.00,
      total: 1000.00
    },
    port: {
      ticketMedio: 10000.00,
      percentualMedio: 2.53,
      digital: 1000.00,
      smsWhatsapp: 1000.00,
      campanhaUra: 1000.00,
      afiliados: 1000.00,
      total: 1000.00
    },
    contaDigital: {
      ticketMedio: 80.00,
      percentualMedio: 100,
      digital: 1000.00,
      smsWhatsapp: 1000.00,
      campanhaUra: 1000.00,
      afiliados: 1000.00,
      total: 1000.00
    },
    maquininha: {
      ticketMedio: 80.00,
      percentualMedio: 100,
      digital: 1000.00,
      smsWhatsapp: 1000.00,
      campanhaUra: 1000.00,
      afiliados: 1000.00,
      total: 1000.00
    }
  },
  totalProducao: {
    produtos: ['margem', 'fgts', 'cartao', 'saque', 'refin', 'port', 'contaDigital', 'maquininha'],
    canais: ['digital', 'sms', 'ura', 'afiliados'],
    valorVendido: 1000.00,
    valorComissao: 1000.00,
    valorCustos: 1000.00
  },
  sistema: {
    valorImplementacao: 43000,
    valorManuntencao: 9800,
    custoSistema: 1000.00
  },
  resultados: {
    total: 1000.00,
    porcentagem: 80000.00,
    tabela: [
      {
        canal: 'DIGITAL',
        valor: 1000.00,
        lucro: 0.25,
        graph: 0.5
      },
      {
        canal: 'SMS/WHATSAPP',
        valor: 1000.00,
        lucro: 0.25,
        graph: 0.1
      },
      {
        canal: 'URA',
        valor: 1000.00,
        lucro: 0.25,
        graph: 0.1
      },
      {
        canal: 'AFILIADOS',
        valor: 1000.00,
        lucro: 0.25,
        graph: 0.3
      }
    ]
  }
}
