import React from 'react'

import { Skeleton } from 'antd'

function InputSkeleton ({ ...rest }) {
  return (
    <div className="skeleton-input-container">
      <Skeleton.Button
        active={true}
        size="large"
        shape='square'
        {...rest}
      />
    </div>
  )
}

export default InputSkeleton
