import { finalCaculation } from './constants'
import { ICalculator } from './interfaces'

const INITIALSTATE: ICalculator = finalCaculation

const calculator = (state = INITIALSTATE, action: any) => {
  switch (action.type) {
    case 'SET_CALC': {
      return {
        ...state,
        ...action.payload.calc
      }
    }

    default:
      return state
  }
}

export default calculator
