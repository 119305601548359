import React from 'react'
import { InputNumber as AntInput } from 'antd'
import Skeleton from 'components/Skeleton/Skeleton'

interface IInputProps {
  value?: string | number
  loading?: boolean
  min?: number
  controls?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  disabled?: boolean | true
  max?: number
}

function InputNumber ({ value = '', loading = false, min, max, controls, onBlur, disabled, ...rest }: IInputProps) {
  return (
    loading
      ? <Skeleton />
      : <AntInput
        onBlur={onBlur}
        value={value}
        min={min}
        max={max}
        controls={controls}
        disabled={disabled}
        {...rest}
      />
  )
}

export default InputNumber
