import React, { ReactNode } from 'react'
import defaultBackgroundWeb from 'assets/calculator-background.gif'

type Props = {
  children: ReactNode
}

function AppLayout ({ children }: Props) {
  return (
    <div className="app-layout">
      <div className="app-layout__cover">
        <img className="app-layout__backgroundWeb" src={defaultBackgroundWeb} alt="" />
      </div>
      <section className="app-layout__application-container">
        {children}
      </section>
    </div>
  )
}

export default AppLayout
