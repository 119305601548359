import { ICalculator } from 'store/modules/calculator/interfaces'
import format from './format'

const calculatorAgx = (startCalculation: ICalculator): ICalculator => {
  //  Aquiring custos
  const smsInvestimento = startCalculation?.custoSms?.valorInvestimento
  const digitalInvestimento = startCalculation?.custoDigital?.valorInvestimento
  const uraInvestimento = startCalculation?.custoUra?.valorInvestimento
  const afiliadosInvestimento = startCalculation?.custoAfiliados?.valorInvestimento

  const smsCusto = startCalculation?.custoSms?.custoOperacao
  const digitalCusto = startCalculation?.custoDigital?.custoOperacao
  const uraCusto = startCalculation?.custoUra?.custoOperacao
  const afiliadosCusto = startCalculation?.custoAfiliados?.custoOperacao

  const smsComissao = startCalculation?.custoSms?.comissao / 100
  const digitalComissao = startCalculation?.custoDigital?.comissao / 100
  const uraComissao = startCalculation?.custoUra?.comissao / 100
  const afiliadosComissao = startCalculation?.custoAfiliados?.comissao / 100

  // Aquiring ticketMedio e percentualMedio
  const margemTicketMedio = startCalculation?.produtos?.margem?.ticketMedio
  const margemPercentualMedio = startCalculation?.produtos?.margem?.percentualMedio / 100
  const fgtsTicketMedio = startCalculation?.produtos?.fgts?.ticketMedio
  const fgtsPercentualMedio = startCalculation?.produtos?.fgts?.percentualMedio / 100
  const cartaoTicketMedio = startCalculation?.produtos?.cartao?.ticketMedio
  const cartaoPercentualMedio = startCalculation?.produtos?.cartao?.percentualMedio / 100
  const saqueTicketMedio = startCalculation?.produtos?.saque?.ticketMedio
  const saquePercentualMedio = startCalculation?.produtos?.saque?.percentualMedio / 100
  const refinTicketMedio = startCalculation?.produtos?.refin?.ticketMedio
  const refinPercentualMedio = startCalculation?.produtos?.refin?.percentualMedio / 100
  const portTicketMedio = startCalculation?.produtos?.port?.ticketMedio
  const portPercentualMedio = startCalculation?.produtos?.port?.percentualMedio / 100
  const contaDigitalTicketMedio = startCalculation?.produtos?.contaDigital?.ticketMedio
  const contaDigitalPercentualMedio = startCalculation?.produtos?.contaDigital?.percentualMedio / 100
  const maquininhaTicketMedio = startCalculation?.produtos?.maquininha?.ticketMedio
  const maquininhaPercentualMedio = startCalculation?.produtos?.maquininha?.percentualMedio / 100

  //  aquiring conversaoDias
  const conversaoVendaDiasUteis = startCalculation?.conversaoVenda?.diasUteis
  const conversaoVendaVenda = startCalculation?.conversaoVenda?.vendas

  // aquiring products
  const produtosSelecionados = startCalculation?.totalProducao?.produtos
  const canaisSelecionados = startCalculation?.totalProducao?.canais

  // aquiring valores de sistema
  const valorImplementacao = startCalculation?.sistema?.valorImplementacao
  const valorManutencao = startCalculation?.sistema?.valorManuntencao

  // calculating constants
  const setValues = {
    sms: {
      unitario: 0.06,
      conv: 0.000011
    },
    digital: {
      unitario: 232.9,
      conv: 0.0000009
    },
    ura: {
      unitario: 4.5,
      conv: 0.0002
    }
  }

  const constantesDeCalculo = {
    sms: {
      invest: smsInvestimento,
      view: smsInvestimento / setValues.sms.unitario,
      unitario: setValues.sms.unitario,
      conv: setValues.sms.conv,
      capturaDia: (smsInvestimento / setValues.sms.unitario) * setValues.sms.conv,
      dias: conversaoVendaDiasUteis,
      mes: ((smsInvestimento / setValues.sms.unitario) * setValues.sms.conv) * conversaoVendaDiasUteis
    },
    digital: {
      invest: digitalInvestimento,
      view: digitalInvestimento * setValues.digital.unitario,
      unitario: setValues.digital.unitario,
      conv: setValues.digital.conv,
      capturaDia: (digitalInvestimento * setValues.digital.unitario) * setValues.digital.conv,
      dias: conversaoVendaDiasUteis,
      mes: ((digitalInvestimento * setValues.digital.unitario) * setValues.digital.conv) * conversaoVendaDiasUteis
    },
    ura: {
      invest: uraInvestimento,
      view: uraInvestimento * setValues.ura.unitario,
      unitario: setValues.ura.unitario,
      conv: setValues.ura.conv,
      capturaDia: (uraInvestimento * setValues.ura.unitario) * setValues.ura.conv,
      dias: conversaoVendaDiasUteis,
      mes: ((uraInvestimento * setValues.ura.unitario) * setValues.ura.conv) * conversaoVendaDiasUteis
    },
    afiliados: {
      invest: afiliadosInvestimento,
      unitario: conversaoVendaVenda / 30,
      dias: conversaoVendaDiasUteis,
      mes: (conversaoVendaVenda / 30) * conversaoVendaDiasUteis * afiliadosInvestimento
    }
  }

  // CALCULOS
  const totalTickets = constantesDeCalculo.digital.mes +
                       constantesDeCalculo.sms.mes +
                       constantesDeCalculo.ura.mes +
                       constantesDeCalculo.afiliados.mes

  const calculosPorProduto = {
    margem: {
      digital: margemTicketMedio * margemPercentualMedio * constantesDeCalculo.digital.mes,
      sms: margemTicketMedio * margemPercentualMedio * constantesDeCalculo.sms.mes,
      ura: margemTicketMedio * margemPercentualMedio * constantesDeCalculo.ura.mes,
      afiliados: margemTicketMedio * margemPercentualMedio * constantesDeCalculo.afiliados.mes
    },
    fgts: {
      digital: fgtsTicketMedio * fgtsPercentualMedio * constantesDeCalculo.digital.mes,
      sms: fgtsTicketMedio * fgtsPercentualMedio * constantesDeCalculo.sms.mes,
      ura: fgtsTicketMedio * fgtsPercentualMedio * constantesDeCalculo.ura.mes,
      afiliados: fgtsTicketMedio * fgtsPercentualMedio * constantesDeCalculo.afiliados.mes
    },
    cartao: {
      digital: cartaoTicketMedio * cartaoPercentualMedio * constantesDeCalculo.digital.mes,
      sms: cartaoTicketMedio * cartaoPercentualMedio * constantesDeCalculo.sms.mes,
      ura: cartaoTicketMedio * cartaoPercentualMedio * constantesDeCalculo.ura.mes,
      afiliados: cartaoTicketMedio * cartaoPercentualMedio * constantesDeCalculo.afiliados.mes
    },
    saque: {
      digital: saqueTicketMedio * saquePercentualMedio * constantesDeCalculo.digital.mes,
      sms: saqueTicketMedio * saquePercentualMedio * constantesDeCalculo.sms.mes,
      ura: saqueTicketMedio * saquePercentualMedio * constantesDeCalculo.ura.mes,
      afiliados: saqueTicketMedio * saquePercentualMedio * constantesDeCalculo.afiliados.mes
    },
    refin: {
      digital: refinTicketMedio * refinPercentualMedio * constantesDeCalculo.digital.mes,
      sms: refinTicketMedio * refinPercentualMedio * constantesDeCalculo.sms.mes,
      ura: refinTicketMedio * refinPercentualMedio * constantesDeCalculo.ura.mes,
      afiliados: refinTicketMedio * refinPercentualMedio * constantesDeCalculo.afiliados.mes
    },
    port: {
      digital: portTicketMedio * portPercentualMedio * constantesDeCalculo.digital.mes,
      sms: portTicketMedio * portPercentualMedio * constantesDeCalculo.sms.mes,
      ura: portTicketMedio * portPercentualMedio * constantesDeCalculo.ura.mes,
      afiliados: portTicketMedio * portPercentualMedio * constantesDeCalculo.afiliados.mes
    },
    contaDigital: {
      digital: contaDigitalTicketMedio * contaDigitalPercentualMedio * constantesDeCalculo.digital.mes,
      sms: contaDigitalTicketMedio * contaDigitalPercentualMedio * constantesDeCalculo.sms.mes,
      ura: contaDigitalTicketMedio * contaDigitalPercentualMedio * constantesDeCalculo.ura.mes,
      afiliados: contaDigitalTicketMedio * contaDigitalPercentualMedio * constantesDeCalculo.afiliados.mes
    },
    maquininha: {
      digital: maquininhaTicketMedio * maquininhaPercentualMedio * constantesDeCalculo.digital.mes,
      sms: maquininhaTicketMedio * maquininhaPercentualMedio * constantesDeCalculo.sms.mes,
      ura: maquininhaTicketMedio * maquininhaPercentualMedio * constantesDeCalculo.ura.mes,
      afiliados: maquininhaTicketMedio * maquininhaPercentualMedio * constantesDeCalculo.afiliados.mes
    }

  }

  const comissoesPorCanal = {
    sms: smsComissao,
    digital: digitalComissao,
    ura: uraComissao,
    afiliados: afiliadosComissao
  }

  // MARGEM
  const margemTotal = calculosPorProduto.margem.digital +
                      calculosPorProduto.margem.sms +
                      calculosPorProduto.margem.ura +
                      calculosPorProduto.margem.afiliados
  // FGTS
  const fgtsTotal = calculosPorProduto.fgts.digital +
                      calculosPorProduto.fgts.sms +
                      calculosPorProduto.fgts.ura +
                      calculosPorProduto.fgts.afiliados
  // CARTAO
  const cartaoTotal = calculosPorProduto.cartao.digital +
                      calculosPorProduto.cartao.sms +
                      calculosPorProduto.cartao.ura +
                      calculosPorProduto.cartao.afiliados
  // SAQUE
  const saqueTotal = calculosPorProduto.saque.digital +
                      calculosPorProduto.saque.sms +
                      calculosPorProduto.saque.ura +
                      calculosPorProduto.saque.afiliados
  // REFIN
  const refinTotal = calculosPorProduto.refin.digital +
                      calculosPorProduto.refin.sms +
                      calculosPorProduto.refin.ura +
                      calculosPorProduto.refin.afiliados
  // PORT
  const portTotal = calculosPorProduto.port.digital +
                      calculosPorProduto.port.sms +
                      calculosPorProduto.port.ura +
                      calculosPorProduto.port.afiliados
  // CONTA DIGITAL
  const contaDigitalTotal = calculosPorProduto.contaDigital.digital +
                      calculosPorProduto.contaDigital.sms +
                      calculosPorProduto.contaDigital.ura +
                      calculosPorProduto.contaDigital.afiliados
  // MAQUININHA
  const maquininhaTotal = calculosPorProduto.maquininha.digital +
                      calculosPorProduto.maquininha.sms +
                      calculosPorProduto.maquininha.ura +
                      calculosPorProduto.maquininha.afiliados

  // PRODUTOS
  // selecao
  const custoSistema = (valorImplementacao / 12) + valorManutencao
  let totalVendido = 0
  let totalComissao = 0
  const smsCustoCanal = canaisSelecionados.includes('sms') ? smsCusto : 0
  const digitalCustoCanal = canaisSelecionados.includes('digital') ? digitalCusto : 0
  const uraCustoCanal = canaisSelecionados.includes('ura') ? uraCusto : 0
  const afiliadosCustoCanal = canaisSelecionados.includes('afiliados') ? afiliadosCusto : 0
  const totalCustosPorCanais = smsCustoCanal + digitalCustoCanal + uraCustoCanal + afiliadosCustoCanal

  const totalCustos = custoSistema + totalCustosPorCanais
  const totalPorProduto = {
    digital: {
      totalVendido: 0,
      digitalCusto,
      comissao: 0
    },
    sms: {
      totalVendido: 0,
      smsCusto,
      comissao: 0
    },
    ura: {
      totalVendido: 0,
      uraCusto,
      comissao: 0
    },
    afiliados: {
      totalVendido: 0,
      afiliadosCusto,
      comissao: 0
    }
  }

  for (const produto of produtosSelecionados) {
    for (const canal of canaisSelecionados) {
      totalVendido += (calculosPorProduto as any)[`${produto}`][`${canal}`]
      totalComissao += (calculosPorProduto as any)[`${produto}`][`${canal}`] * (comissoesPorCanal as any)[`${canal}`]
    }
  }

  for (const canal of canaisSelecionados) {
    for (const produto of produtosSelecionados) {
      (totalPorProduto as any)[`${canal}`].totalVendido += (calculosPorProduto as any)[`${produto}`][`${canal}`];
      (totalPorProduto as any)[`${canal}`].comissao += (calculosPorProduto as any)[`${produto}`][`${canal}`] * (comissoesPorCanal as any)[`${canal}`]
    }
  }

  const custoRateadoPorProdutos = canaisSelecionados.length > 0 ? custoSistema / canaisSelecionados.length : 0

  //  RESULTADOS
  const resultados = {
    total: totalVendido - custoSistema - totalComissao - totalCustosPorCanais,
    porcentagem: ((totalVendido - custoSistema - totalComissao - totalCustosPorCanais) / (totalComissao + totalCustos)) * 100,
    tabela: [
      {
        canal: 'DIGITAL',
        valor: canaisSelecionados.includes('digital') ? Number((totalPorProduto.digital.totalVendido - totalPorProduto.digital.comissao - totalPorProduto.digital.digitalCusto - custoRateadoPorProdutos).toFixed(2)) : 0,
        lucro: canaisSelecionados.includes('digital') ? ((totalPorProduto.digital.totalVendido - totalPorProduto.digital.comissao - totalPorProduto.digital.digitalCusto - custoRateadoPorProdutos) / (custoRateadoPorProdutos + totalPorProduto.digital.comissao + digitalCusto)) * 100 : 0,
        graph: canaisSelecionados.includes('digital') ? (totalPorProduto.digital.totalVendido - totalPorProduto.digital.comissao - totalPorProduto.digital.digitalCusto - custoRateadoPorProdutos) / (totalVendido - custoRateadoPorProdutos - totalComissao - totalCustosPorCanais) : 0
      },
      {
        canal: 'SMS/WHATSAPP',
        valor: canaisSelecionados.includes('sms') ? Number((totalPorProduto.sms.totalVendido - totalPorProduto.sms.comissao - totalPorProduto.sms.smsCusto - custoRateadoPorProdutos).toFixed(2)) : 0,
        lucro: canaisSelecionados.includes('sms') ? ((totalPorProduto.sms.totalVendido - totalPorProduto.sms.comissao - totalPorProduto.sms.smsCusto - custoRateadoPorProdutos) / (custoRateadoPorProdutos + totalPorProduto.sms.comissao + smsCusto)) * 100 : 0,
        graph: canaisSelecionados.includes('sms') ? (totalPorProduto.sms.totalVendido - totalPorProduto.sms.comissao - totalPorProduto.sms.smsCusto - custoRateadoPorProdutos) / (totalVendido - custoRateadoPorProdutos - totalComissao - totalCustosPorCanais) : 0
      },
      {
        canal: 'URA',
        valor: canaisSelecionados.includes('ura') ? Number((totalPorProduto.ura.totalVendido - totalPorProduto.ura.comissao - totalPorProduto.ura.uraCusto - custoRateadoPorProdutos).toFixed(2)) : 0,
        lucro: canaisSelecionados.includes('ura') ? ((totalPorProduto.ura.totalVendido - totalPorProduto.ura.comissao - totalPorProduto.ura.uraCusto - custoRateadoPorProdutos) / (custoRateadoPorProdutos + totalPorProduto.ura.comissao + uraCusto)) * 100 : 0,
        graph: canaisSelecionados.includes('ura') ? (totalPorProduto.ura.totalVendido - totalPorProduto.ura.comissao - totalPorProduto.ura.uraCusto - custoRateadoPorProdutos) / (totalVendido - custoRateadoPorProdutos - totalComissao - totalCustosPorCanais) : 0
      },
      {
        canal: 'AFILIADOS',
        valor: canaisSelecionados.includes('afiliados') ? Number((totalPorProduto.afiliados.totalVendido - totalPorProduto.afiliados.comissao - totalPorProduto.afiliados.afiliadosCusto - custoRateadoPorProdutos).toFixed(2)) : 0,
        lucro: canaisSelecionados.includes('afiliados') ? ((totalPorProduto.afiliados.totalVendido - totalPorProduto.afiliados.comissao - totalPorProduto.afiliados.afiliadosCusto - custoRateadoPorProdutos) / (custoRateadoPorProdutos + totalPorProduto.afiliados.comissao + afiliadosCusto)) * 100 : 0,
        graph: canaisSelecionados.includes('afiliados') ? (totalPorProduto.afiliados.totalVendido - totalPorProduto.afiliados.comissao - totalPorProduto.afiliados.afiliadosCusto - custoRateadoPorProdutos) / (totalVendido - custoRateadoPorProdutos - totalComissao - totalCustosPorCanais) : 0
      }
    ]
  }

  const finalCaculation = {
    custoSms: {
      valorInvestimento: smsInvestimento,
      custoOperacao: smsCusto,
      comissao: smsComissao * 100
    },
    custoDigital: {
      valorInvestimento: digitalInvestimento,
      custoOperacao: digitalCusto,
      comissao: digitalComissao * 100
    },
    custoUra: {
      valorInvestimento: uraInvestimento,
      custoOperacao: uraCusto,
      comissao: uraComissao * 100
    },
    custoAfiliados: {
      valorInvestimento: afiliadosInvestimento,
      custoOperacao: afiliadosCusto,
      comissao: afiliadosComissao * 100
    },
    conversaoVenda: {
      diasUteis: conversaoVendaDiasUteis,
      vendas: conversaoVendaVenda
    },
    ticketCanal: {
      digital: Math.round(constantesDeCalculo.digital.mes),
      smsWhatsapp: Math.round(constantesDeCalculo.sms.mes),
      campanhaUra: Math.round(constantesDeCalculo.ura.mes),
      afiliados: Math.round(constantesDeCalculo.afiliados.mes),
      totalTickets: Math.round(totalTickets)
    },
    produtos: {
      margem: {
        ticketMedio: margemTicketMedio,
        percentualMedio: format.precision(margemPercentualMedio * 100),
        digital: format.precision(calculosPorProduto.margem.digital),
        smsWhatsapp: format.precision(calculosPorProduto.margem.sms),
        campanhaUra: format.precision(calculosPorProduto.margem.ura),
        afiliados: format.precision(calculosPorProduto.margem.afiliados),
        total: format.precision(margemTotal)
      },
      fgts: {
        ticketMedio: fgtsTicketMedio,
        percentualMedio: format.precision(fgtsPercentualMedio * 100),
        digital: format.precision(calculosPorProduto.fgts.digital),
        smsWhatsapp: format.precision(calculosPorProduto.fgts.sms),
        campanhaUra: format.precision(calculosPorProduto.fgts.ura),
        afiliados: format.precision(calculosPorProduto.fgts.afiliados),
        total: format.precision(fgtsTotal)
      },
      cartao: {
        ticketMedio: cartaoTicketMedio,
        percentualMedio: format.precision(cartaoPercentualMedio * 100),
        digital: format.precision(calculosPorProduto.cartao.digital),
        smsWhatsapp: format.precision(calculosPorProduto.cartao.sms),
        campanhaUra: format.precision(calculosPorProduto.cartao.ura),
        afiliados: format.precision(calculosPorProduto.cartao.afiliados),
        total: format.precision(cartaoTotal)
      },
      saque: {
        ticketMedio: saqueTicketMedio,
        percentualMedio: format.precision(saquePercentualMedio * 100),
        digital: format.precision(calculosPorProduto.saque.digital),
        smsWhatsapp: format.precision(calculosPorProduto.saque.sms),
        campanhaUra: format.precision(calculosPorProduto.saque.ura),
        afiliados: format.precision(calculosPorProduto.saque.afiliados),
        total: format.precision(saqueTotal)
      },
      refin: {
        ticketMedio: refinTicketMedio,
        percentualMedio: format.precision(refinPercentualMedio * 100),
        digital: format.precision(calculosPorProduto.refin.digital),
        smsWhatsapp: format.precision(calculosPorProduto.refin.sms),
        campanhaUra: format.precision(calculosPorProduto.refin.ura),
        afiliados: format.precision(calculosPorProduto.refin.afiliados),
        total: format.precision(refinTotal)
      },
      port: {
        ticketMedio: portTicketMedio,
        percentualMedio: format.precision(portPercentualMedio * 100),
        digital: format.precision(calculosPorProduto.port.digital),
        smsWhatsapp: format.precision(calculosPorProduto.port.sms),
        campanhaUra: format.precision(calculosPorProduto.port.ura),
        afiliados: format.precision(calculosPorProduto.port.afiliados),
        total: format.precision(portTotal)
      },
      contaDigital: {
        ticketMedio: contaDigitalTicketMedio,
        percentualMedio: format.precision(contaDigitalPercentualMedio * 100),
        digital: format.precision(calculosPorProduto.contaDigital.digital),
        smsWhatsapp: format.precision(calculosPorProduto.contaDigital.sms),
        campanhaUra: format.precision(calculosPorProduto.contaDigital.ura),
        afiliados: format.precision(calculosPorProduto.contaDigital.afiliados),
        total: format.precision(contaDigitalTotal)
      },
      maquininha: {
        ticketMedio: maquininhaTicketMedio,
        percentualMedio: format.precision(maquininhaPercentualMedio * 100),
        digital: format.precision(calculosPorProduto.maquininha.digital),
        smsWhatsapp: format.precision(calculosPorProduto.maquininha.sms),
        campanhaUra: format.precision(calculosPorProduto.maquininha.ura),
        afiliados: format.precision(calculosPorProduto.maquininha.afiliados),
        total: format.precision(maquininhaTotal)
      }
    },
    totalProducao: {
      produtos: produtosSelecionados,
      canais: canaisSelecionados,
      valorVendido: format.precision(totalVendido),
      valorComissao: format.precision(totalComissao),
      valorCustos: format.precision(totalCustos)
    },
    sistema: {
      valorImplementacao: format.precision(valorImplementacao),
      valorManuntencao: format.precision(valorManutencao),
      custoSistema: format.precision(custoSistema)
    },
    resultados: resultados
  }

  return finalCaculation
}

export default calculatorAgx
