import React from 'react'

export const productsName = {
  margem: 'Margem',
  fgts: 'FGTS',
  cartao: 'Cartão',
  saque: 'Saque',
  refin: 'Refin',
  port: 'Port',
  contaDigital: 'Conta Digital',
  maquininha: 'Maquininha'
}

export const productTooltip = {
  margem: 'Todo Aposentado e Servidor Público têm o direito de consignar até 30% (trinta por cento) do seu salário para Empréstimo Consignado.',
  fgts: 'Todo trabalhador que possui FGTS têm o direito de usá-lo como garantia de Empréstimo Pessoal.',
  cartao: <>Todo Aposentado e Servidor Público têm o direito de usar 5% (cinco por cento) do seu salário como limite de garantia para Cartão de Crédito Consignado.<br/> *Este valor significa apenas à aquisição do Plástico*</>,
  saque: 'Todo Aposentado e Servidor Público que possuir o cartão consignado têm o direito a utilizar até 5% (cinco por cento) do seu salário como saque antecipado, transformando o seu limite em crédito direto em Sua conta Corrente.',
  refin: 'Ao adquirir um Empréstimo em um Banco a cada pagamento de parcela o uso do limite dos 30% (trinta por cento) aumentam. Isso significa que, o aposentado pode refinanciar seu contrato para pegar mais dinheiro no Mesmo Banco.',
  port: 'Ao adquirir o Valor do Empréstimo Consignado em um Banco, o aposentado pode portar para outra instituição financeira.',
  contaDigital: 'Abertura de Conta Digital ',
  maquininha: 'Gateway de pagamento ofertado pelos Bancos para pessoas Físicas e Jurídicas.'
}
