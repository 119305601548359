import React from 'react'
import { Col, Row } from 'antd'

interface IToggleCardExtend {
  background: string | number
  title: string | React.ReactElement
  children: React.ReactNode
}

function FixedCard ({ title, children, background }: IToggleCardExtend) {
  return (
    <div className="fixed-card">
      <div style={{ backgroundColor: `${background}` }} className={'fixed-card__card'}>
        <Row align="middle" className="fixed-card__header">
          <Col flex="1 1" className="center-text">
            <div className={'fixed-card__card-title'}>{title}</div>
          </Col>
        </Row>

      </div>
      <Row className="fixed-card__body" style={{ backgroundColor: `${background}` }}>
        <div className="fixed-card__body-children">
          {children}
        </div>
      </Row>
    </div>
  )
}

export default FixedCard
