import React, { useLayoutEffect, useRef, useState } from 'react'

import { Button, Carousel, Form, FormInstance, Select, Tooltip } from 'antd'
import ToggleCardExtend from 'components/ToggleCardExtend/ToggleCardExtend'
import InputMoney from 'components/InputMoney/InputMoney'
import InputPercentage from 'components/InputPercentage/InputPercentage'
import ProductCard from 'components/ProductCard/ProductCard'
import FixedCard from 'components/FixedCard/FixedCard'
import InputNumber from 'components/InputNumber/InputNumber'

import { useDispatch } from 'react-redux'
import { useCalculator } from 'hooks'
import { useNavigate } from 'react-router-dom'

import calculatorAgx from 'utils/calculatev1'
import { formatCalculation } from './formatCalcs'

import { setCalc } from 'store/modules/calculator/action'

import { allChannel, allProducts, _channels } from './contants'
import tooltipHelper from 'assets/svgs/tooltip-helper.svg'

import { ICalculator } from 'store/modules/calculator/interfaces'
import { CarouselRef } from 'antd/lib/carousel'

function Home () {
  const calculator = useCalculator()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [channelOptions, setChannelOptions] = useState(allChannel)
  const carouselRefs = useRef<CarouselRef>(null)

  const formRef = useRef<FormInstance<ICalculator>>(null)
  const resetFields = () => {
    if (formRef && formRef?.current) formRef?.current?.resetFields()
  }
  const handleChannels = (calculation: ICalculator):ICalculator => {
    const channelSelected:_channels[] = []

    if (calculation.custoAfiliados.valorInvestimento > 0) channelSelected.push('afiliados')
    if (calculation.custoDigital.valorInvestimento > 0) channelSelected.push('digital')
    if (calculation.custoSms.valorInvestimento > 0) channelSelected.push('sms')
    if (calculation.custoUra.valorInvestimento > 0) channelSelected.push('ura')

    calculation.totalProducao.canais = calculation.totalProducao.canais.filter(channel => channelSelected.includes(channel as _channels))

    const channelOptions = allChannel.filter(channel => channelSelected.includes(channel.value as _channels))
    setChannelOptions(channelOptions)

    return calculation
  }

  const fillFields = () => {
    let data: ICalculator = JSON.parse(JSON.stringify(calculator))

    if (data) {
      const finalCalculation = handleChannels(formatCalculation(data))

      data = calculatorAgx(finalCalculation)

      dispatch(setCalc(data))
      formRef?.current?.setFieldsValue(data)
    } else {
      resetFields()
    }
  }

  useLayoutEffect(() => {
    resetFields()
    fillFields()
  }, [])

  const handleCalc = () => {
    const allValues = formRef?.current?.getFieldsValue()

    const finalCalculation = handleChannels(formatCalculation(allValues as ICalculator))

    const newCalc = calculatorAgx(finalCalculation)

    dispatch(setCalc(newCalc))
    formRef?.current?.setFieldsValue(newCalc)
  }

  return (
    <div>
      <div className="home__header">
        <h1 className="home__main-title">
          Simule
        </h1>
        <p>Indiky</p>
        <p className="home__main-subtitle">Calcule sua máquina de vendas</p>
      </div>

      <Form
        layout="vertical"
        ref={formRef}
      >
              <ToggleCardExtend
          background={'#A17CCE'}
          title={'investimento com digital'}
        >
          <Form.Item
            name={['custoDigital', 'valorInvestimento']}
            label='Valor de investimento mensal'
          >
            <InputMoney placeholder="Digite o valor" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['custoDigital', 'custoOperacao']}
            label='Descreva outros custos com essa operação'
          >
            <InputMoney placeholder="Descreva os outros custos" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['custoDigital', 'comissao']}
            label='Comissão paga aos vendedores'
          >
            <InputPercentage placeholder="Digite a comissão" onBlur={handleCalc} />
          </Form.Item>
        </ToggleCardExtend>

        <ToggleCardExtend
          background={'#312E52'}
          title={'investimento com sms'}
        >
          <Form.Item
            name={['custoSms', 'valorInvestimento']}
            label='Valor de investimento mensal'
          >
            <InputMoney placeholder="Digite o valor" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['custoSms', 'custoOperacao']}
            label='Descreva outros custos com essa operação'
          >
            <InputMoney placeholder="Descreva os outros custos" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['custoSms', 'comissao']}
            label='Comissão paga aos vendedores'
          >
            <InputPercentage placeholder="Digite a comissão" onBlur={handleCalc} />
          </Form.Item>
        </ToggleCardExtend>

        <ToggleCardExtend
          background={'#3AB185'}
          title={'investimento com ura'}
        >
          <Form.Item
            name={['custoUra', 'valorInvestimento']}
            label={
              <div className="product-card__tooltip-container">
                <Tooltip placement="rightTop" title="Valor de 30 canais (R$999,00) na Operadora Vivo." >
                  <img className="product-card__tooltip-icon" src={tooltipHelper} alt="" />
                </Tooltip>
                <span>Valor de investimento mensal</span>
              </div>
            }
          >
            <InputMoney placeholder="Digite o valor" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['custoUra', 'custoOperacao']}
            label="Descreva outros custos com essa operação"
          >
            <InputMoney placeholder="Descreva os outros custos" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['custoUra', 'comissao']}
            label='Comissão paga aos vendedores'
          >
            <InputPercentage placeholder="Digite a comissão" onBlur={handleCalc} />
          </Form.Item>
        </ToggleCardExtend>

        <ToggleCardExtend
          background={'#FD7D7F'}
          title={'Afiliados convidados'}
        >
          <Form.Item
            name={['custoAfiliados', 'valorInvestimento']}
            label='Quantidade de afiliados'
          >
            <InputNumber onBlur={handleCalc} min={0} controls={false} />
          </Form.Item>

          <Form.Item
            name={['custoAfiliados', 'custoOperacao']}
            label='Descreva outros custos com essa operação'
          >
            <InputMoney placeholder="Descreva os outros custos" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['custoAfiliados', 'comissao']}
            label='Comissão paga aos vendedores'
          >
            <InputPercentage placeholder="Digite a comissão" onBlur={handleCalc} />
          </Form.Item>
        </ToggleCardExtend>

        <FixedCard
          background={'#9EA2AC'}
          title={'ticket - canal'}
        >
          <Form.Item
            name={['ticketCanal', 'digital']}
            label="Google, Facebook, Instagram"
          >
            <InputNumber
              disabled
              min={0}
              controls={false}
            />
          </Form.Item>

          <Form.Item
            name={['ticketCanal', 'smsWhatsapp']}
            label="SMS e WhatsApp"
          >
            <InputNumber
              disabled
              min={0}
              controls={false}
            />
          </Form.Item>

          <Form.Item
            name={['ticketCanal', 'campanhaUra']}
            label="Campanha de URA"
          >
            <InputNumber
              disabled
              min={0}
              controls={false}
            />
          </Form.Item>

          <Form.Item
            name={['ticketCanal', 'afiliados']}
            label="Afiliados"
          >
            <InputNumber
              disabled
              min={0}
              controls={false}
            />
          </Form.Item>

          <Form.Item
            name={['ticketCanal', 'totalTickets']}
            label="Quantidade total de tickets"
          >
            <InputNumber
              disabled
              min={0}
              controls={false}
            />
          </Form.Item>
        </FixedCard>

        <div className="home__seller-conversion">
          <h1 className='home__title  home__title--min' >Conversão de venda de produto e canal</h1>
          <div className="home__container-channel">
            <label className="home__tooltip-container">
              <Tooltip placement="rightTop" title="Dias úteis em operação." >
                <img className="home__tooltip-icon" src={tooltipHelper} alt="" />
              </Tooltip>
              <span className="home__label-seller">QTD. de dias úteis</span>
            </label>
            <Form.Item
              name={['conversaoVenda', 'diasUteis']}
              className="home__input-number-custom"
            >
              <InputNumber onBlur={handleCalc} min={0} max={30} />
            </Form.Item>
          </div>

          <div className="home__container-channel">
            <label className="home__label-seller">venda por mês/afiliados</label>
            <Form.Item
              name={['conversaoVenda', 'vendas']}
              className="home__input-number-custom"
            >
              <InputNumber onBlur={handleCalc} min={0} />
            </Form.Item>
          </div>

        </div>

        <h1 className='home__title'>SEUS PRODUTOS</h1>
        <Carousel
          draggable
          centerMode
          infinite
          ref={carouselRefs}
        >
          {calculator.produtos && Object.keys(calculator.produtos).map((product) => (
            <ProductCard
              refs={carouselRefs}
              key={product}
              product={product}
              onBlur={handleCalc} />
          ))}
        </Carousel>

        <h1 className='home__title'>Resultado da operação por produto</h1>
        <FixedCard
          title="Sistema"
          background="#312E52"
        >
          <Form.Item
            name={['sistema', 'valorImplementacao']}
            label='Valor de Implementação do Sistema'
            data-cy='valorImplementacao'
          >
            <InputMoney placeholder="Digite o valor" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['sistema', 'valorManuntencao']}
            label='Valor de Manutenção Mensal'
            data-cy='valorManuntencao'
          >
            <InputMoney placeholder="Digite o valor" onBlur={handleCalc} />
          </Form.Item>

          <Form.Item
            name={['sistema', 'custoSistema']}
            label='Total do White Label + Manutenção'
            data-cy='valorManuntencao'
          >
            <InputMoney placeholder="Digite o total" disabled suffix=' por mês' />
          </Form.Item>

        </FixedCard>

        <FixedCard
          title="total de produção"
          background="#312E52"
        >
          <Form.Item
            name={['totalProducao', 'produtos']}
            label='Selecione os produtos desejados'
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione os produtos"
              maxTagCount="responsive"
              onChange={handleCalc}
              options={allProducts}
            />
          </Form.Item>

          <Form.Item
            name={['totalProducao', 'canais']}
            label='Selecione os canais desejados'
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione os canais"
              maxTagCount="responsive"
              onChange={handleCalc}
              options={channelOptions}
            />
          </Form.Item>

          <Form.Item
            name={['totalProducao', 'valorVendido']}
            label='Valor vendido'
          >
            <InputMoney placeholder="Digite o total" disabled />
          </Form.Item>

          <Form.Item
            name={['totalProducao', 'valorComissao']}
            label='Valor de comissão'
          >
            <InputMoney placeholder="Digite o valor" disabled />
          </Form.Item>

          <Form.Item
            name={['totalProducao', 'valorCustos']}
            label='Valor de custos'
          >
            <InputMoney placeholder="Digite o valor" disabled />
          </Form.Item>

        </FixedCard>

        <Button
          type="primary"
          onClick={() => navigate('/results')}
          className="home__button-results"
        >
          MEU RESULTADO
        </Button>
      </Form >

    </div >
  )
}

export default Home
